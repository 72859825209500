import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/b7146c2/components/layout-content.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Changelog`}</h1>
    <p><inlineCode parentName="p">{`compai`}</inlineCode>{` is being constantly updated. All bugfixes and new features
will be listed below.`}</p>
    <h2>{`8/1/2019`}</h2>
    <ul>
      <li parentName="ul">{`Bugfixes in `}<a parentName="li" {...{
          "href": "/text-images/"
        }}>{`text images`}</a></li>
      <li parentName="ul">{`Implemented saved colors`}</li>
    </ul>
    <h2>{`7/28/2019`}</h2>
    <ul>
      <li parentName="ul">{`Implemented `}<a parentName="li" {...{
          "href": "/text-images/"
        }}>{`text images`}</a></li>
    </ul>
    <h2>{`7/16/2019`}</h2>
    <ul>
      <li parentName="ul">{`Implemented `}<a parentName="li" {...{
          "href": "/text-gradients/"
        }}>{`text gradients`}</a></li>
    </ul>
    <h2>{`7/11/2019`}</h2>
    <ul>
      <li parentName="ul">{`Implemented `}<a parentName="li" {...{
          "href": "/text-shadows/"
        }}>{`text shadows`}</a></li>
    </ul>
    <h2>{`7/5/2019`}</h2>
    <ul>
      <li parentName="ul">{`Implemented `}<a parentName="li" {...{
          "href": "/color/"
        }}>{`color`}</a></li>
      <li parentName="ul">{`Add ability to set background color on `}<a parentName="li" {...{
          "href": "/box-shadows/"
        }}>{`box shadow canvas`}</a></li>
    </ul>
    <h2>{`7/4/2019`}</h2>
    <ul>
      <li parentName="ul">{`Implemented `}<a parentName="li" {...{
          "href": "/box-shadows/"
        }}>{`box shadows`}</a></li>
    </ul>
    <h2>{`7/1/2019`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/gradients/votes/share?id=yqHU5RchPUHiI9ceVuLQ"
        }}>{`Gradient sharing`}</a></li>
    </ul>
    <h2>{`6/30/2019`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/gradients/votes/"
        }}>{`Ability to view past gradient votes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/font-pairings/"
        }}>{`Font pairings tool`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/stripes/"
        }}>{`Stripes tool`}</a></li>
      <li parentName="ul">{`Mobile improvements`}</li>
      <li parentName="ul">{`New landing page`}</li>
    </ul>
    <h2>{`6/28/2019`}</h2>
    <ul>
      <li parentName="ul">{`Implemented the grid view for gradients with pinning`}</li>
    </ul>
    <h2>{`6/27/2019`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/gradients/"
        }}>{`Implemented gradients app screen`}</a></li>
      <li parentName="ul">{`Added newsletter signup`}</li>
    </ul>
    <h2>{`6/21/2019`}</h2>
    <ul>
      <li parentName="ul">{`Implemented first API endpoints`}</li>
    </ul>
    <h2>{`8/12/2017`}</h2>
    <ul>
      <li parentName="ul">{`First commit followed by numerous rewrites and changes in app functionality`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      